import React, { useEffect, useState } from "react";
import { get, postAPI } from "../../../ApiService";
import { CButton} from '@coreui/react'
import Swal from "sweetalert2";

function PreScreenLinkQuestion(props){
    // console.log('propsss', props)
    const [questionDetail, setQuestionDetail] = useState();
    const [enabledOpt, setEnabledOpt] = useState([])
    const [validateOpt, setValidateOpt] = useState([])
    const [validateCountError, setValidateCountError] = useState()
    const [allEnabled,setAllEnabled] = useState(false)
    const [allValidated,setAllValidated] = useState(false)

    useEffect(() => {
        if(props?.preScreenerQuestionID && props?.mappedQuestionDetail?.isTempQuestion === 0){
            mappedQuestionDetail()
        }
        else if(props?.preScreenerQuestionID){
            getTempQueDetail()
        }
        else if(props?.queBySearch){
            setQuestionDetail(props?.queBySearch)
            let selMappedOpt = []
            let selValOpt = []
            props?.queBySearch?.mappedOptions?.forEach(ele => {
                if(ele?.enabled === 1){
                    selMappedOpt.push(ele?.option)
                }
                if(ele?.validate === 1){
                    selValOpt.push(ele?.option)
                }
                
            });
            setEnabledOpt(selMappedOpt)
            setValidateOpt(selValOpt)
            if(selMappedOpt.length == props?.queBySearch?.mappedOptions?.length){
                setAllEnabled(true);
            }
            if(selValOpt.length == props?.queBySearch?.mappedOptions?.length){
                setAllValidated(true);
            }
            
        }
        
        if(props?.queBySearch === null && !props?.preScreenerQuestionID){
            cancel()
        }
    },[])

    const getTempQueDetail = async()=>{
        let payload = {
            preScreenerQuestionID: props?.preScreenerQuestionID,
            projectDetailID: props?.detail?.projectDetailID,
        }
        let res = await postAPI('/project/prescreen/get-temp-question-detail/',payload)
        if(res?.status === 1){
            setQuestionDetail(res?.payload?.[0])
            let selMappedOpt = []
            let selValOpt = []
            res?.payload?.[0]?.mappedOptions.forEach(ele => {
                if(ele?.enabled === 1){
                    selMappedOpt.push(ele?.option)
                }
                if(ele?.validate === 1){
                    selValOpt.push(ele?.option)
                }
                
            });
            setEnabledOpt(selMappedOpt)
            setValidateOpt(selValOpt)
            if(selMappedOpt.length == res?.payload?.[0]?.mappedOptions?.length){
                setAllEnabled(true);
            }
            if(selValOpt.length == res?.payload?.[0]?.mappedOptions?.length){                
                setAllValidated(true);
            }
        }
    }

    const handleEnableAll = (e) => {
        const { value, checked } = e.target;
        let getCHk = document.getElementsByClassName("chkEnable")
        let getCHkValidate = document.getElementsByClassName("chkValidate")
        let getInputQuota = document.getElementsByClassName("quotainput")
        if (checked) {
            let selMappedOpt = []
            questionDetail?.mappedOptions?.map((opt) => {
                selMappedOpt.push(opt?.option)
            })
            for (let index = 0; index < getCHk.length; index++) {
                const element = getCHk[index];
                const eleValidate = getCHkValidate[index]
                element.checked = true;
                eleValidate.disabled = false;
            }
            setEnabledOpt(selMappedOpt)
        }
        if (!checked) {
            setEnabledOpt([])
            for (let index = 0; index < getCHk.length; index++) {
                const element = getCHk[index];
                const eleValidate = getCHkValidate[index]
                const eleQuota = getInputQuota[index]
                element.checked = false;
                eleValidate.checked = false;
                eleValidate.disabled = true
                eleQuota.value=''
                eleQuota.disabled = true
                
            }
            let validate = document.getElementById("validateAll")
            validate.checked = false
        }
    }

    const handleEnableIndividual = (e) => {
        const { value, checked } = e.target;
        let getCHkValidate = document.getElementById("chkValidate"+value?.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_'))
        let getQuota = document.getElementById("quota"+value)
        let eOpt = enabledOpt
        let validOpt = validateOpt
        if (checked) {
            eOpt.push(value)
            getCHkValidate.disabled = false
            
        }else{
            let index = eOpt.findIndex(opt=>opt === value)
            eOpt.splice(index, 1);
            let vindex = validOpt.findIndex(opt=>opt === value)
            if(vindex > -1)
                validOpt.splice(vindex, 1);

            getCHkValidate.disabled = true
            getCHkValidate.checked = false
            getQuota.disabled = true
            getQuota.value = ""
        }
        let getCHk = document.getElementById("enableAll")
        let getCHkValidateAll = document.getElementById("validateAll")
        if(enabledOpt?.length !== questionDetail?.mappedOptions?.length){
            getCHk.checked = false;
            getCHkValidateAll.disabled = true
        }else{
            getCHk.checked = true;
            getCHkValidateAll.disabled = false
        }
        setEnabledOpt(eOpt)
    }

    const handleValidateAll = async (e) => {
        const { value, checked } = e.target;
        let getCHkValidate = document.getElementsByClassName("chkValidate")
        let getInputQuota = document.getElementsByClassName("quotainput")
        if (checked) {
            let selValidateOpt = []
            questionDetail?.mappedOptions?.map((opt) => {
                selValidateOpt.push(opt.option)
            })
            for (let index = 0; index < getCHkValidate.length; index++) {
                const eleValidate = getCHkValidate[index]
                const eleInputQuota = getInputQuota[index]
                eleValidate.checked = true;
                eleInputQuota.disabled = false
            }
            // console.log('selValidateOpt',selValidateOpt)
            setValidateOpt(selValidateOpt)
            // console.log("ee",validateOpt)
        }
        if (!checked) {
            setValidateOpt([])
            for (let index = 0; index < getCHkValidate.length; index++) {
                const eleValidate = getCHkValidate[index]
                const eleInputQuota = getInputQuota[index]
                eleValidate.checked = false;
                eleInputQuota.disabled = true
            }
        }
    }

    const handleValidateIndividual = (e) => {
        const { value, checked } = e.target;
        // let getCHkValidate = document.getElementById("chkValidate"+value)
        let getQuota = document.getElementById("quota"+value)
        let vOpt = validateOpt
        if (checked) {
            vOpt.push(value)
            getQuota.disabled = false
            
        }else{
            let index = vOpt.findIndex(opt=>opt === value)
            vOpt.splice(index, 1);
            getQuota.disabled = true
            getQuota.value = ""
        }
        // let getCHk = document.getElementById("enableAll")
        let getCHkValidateAll = document.getElementById("validateAll")
        if(validateOpt?.length !== enabledOpt.length){
            getCHkValidateAll.checked = false
        }else{
            getCHkValidateAll.checked = true;
        }
        setValidateOpt(vOpt)
    }

    const mappedQuestionDetail = async () => {
        
        let res = await get('/project/precsreen/get-mapped-question-details/'+props?.detail?.projectDetailID+'/'+props?.preScreenerQuestionID)
        if(res?.status === 1){
            setQuestionDetail(res?.payload?.[0])
            let selMappedOpt = []
            let selValOpt = []
            res?.payload?.[0]?.mappedOptions.forEach(ele => {
                if(ele?.enabled === 1){
                    selMappedOpt.push(ele?.option)
                }
                if(ele?.validate === 1){
                    selValOpt.push(ele?.option)
                }
                
            });
            setEnabledOpt(selMappedOpt)
            setValidateOpt(selValOpt)
            if(selMappedOpt.length == res?.payload?.[0]?.mappedOptions?.length){
                setAllEnabled(true);
            }
            if(selValOpt.length == res?.payload?.[0]?.mappedOptions?.length){                
                setAllValidated(true);
            }
        }
    }

    useEffect(() => {
        console.log('allValidated changed:', allValidated);
    }, [allValidated]);

    const mapFromSearch = async() => {
        let mappedOpts = []
        let allOpts = questionDetail?.mappedOptions
        let countValidate = 0;
        // console.log('validateOpt in search',validateOpt)
       
        allOpts?.map((opt) => {
            let enable = 0
            let validate = 0
            let getQuota = document.getElementById("quota"+opt?.option)
            let quota = getQuota?.value
            let eIndex = enabledOpt.findIndex(option=>option === opt?.option)
            if(eIndex > -1){
                enable = 1
            }
            let vField = document.getElementById("chkValidate"+opt?.option?.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_'))
            if(vField?.checked){
                validate = 1
                countValidate = countValidate+1;
            }
            // let vIndex = validateOpt.findIndex(option=>option === opt?.option)
            // if(vIndex > -1){
            //     validate = 1
            //     countValidate = countValidate+1;
            // }
            // let getQuota = document.getElementById("quota"+opt)
            if(quota === ''){
                quota = 0
            }else{
                quota = parseInt(quota)
            }
            // quota = getQuota.value
            mappedOpts.push({option: opt.option, quota: quota, enabled: enable, validate: validate})
        })
        if(allOpts?.length > 0 && countValidate === 0){
            setValidateCountError("Please select at least one option")
            Swal.fire({
                text: 'Please select at least one valid option',
                confirmButtonText: 'Ok',
                showCancelButton: false,
              })
            return false
        }
        let payload = {
            projectDetailID: props?.detail?.projectDetailID,
            questionID: props?.queBySearch?.ID,
            mappedOptions: mappedOpts
        }
        // console.log('payload', payload)
        let res = await postAPI('/project/prescreen/map-question/', payload)
        if(res.status === 1){
            props?.mappedQue()
            props?.visible()
        }
        Swal.fire({
            text: res?.message,
            confirmButtonText: 'Ok',
            showCancelButton: false,
          })
    }

    const mapFromTemp = async() => {
        let mappedOpts = []
        let allOpts = questionDetail?.mappedOptions
        let countValidate = 0;
        // console.log('validateOpt',validateOpt)
        allOpts?.map((opt) => {
            let enable = 0
            let validate = 0
            let getQuota = document.getElementById("quota"+opt?.option)
            let quota = getQuota?.value
            let eIndex = enabledOpt.findIndex(option=>option === opt?.option)
            if(eIndex > -1){
                enable = 1
            }
            let vIndex = validateOpt.findIndex(option=>option === opt?.option)
            if(vIndex > -1){
                validate = 1
                countValidate = countValidate+1;
            }
            // let getQuota = document.getElementById("quota"+opt)
            if(quota === ''){
                quota = 0
            }else{
                quota = parseInt(quota)
            }
            // quota = getQuota.value
            mappedOpts.push({option: opt.option, quota: quota, enabled: enable, validate: validate})
        })
        if(allOpts?.length > 0 && countValidate === 0){
            setValidateCountError("Please select at least one option")
            Swal.fire({
                text: 'Please select at least one valid option',
                confirmButtonText: 'Ok',
                showCancelButton: false,
              })
            return false
        }
        let payload = {
            projectDetailID: props?.detail?.projectDetailID,
            preScreenerQuestionID: props?.preScreenerQuestionID,
            mappedOptions: mappedOpts
        }
        let res = await postAPI('/project/prescreen/map-temp-question/', payload)
        if(res.status === 1){
            props?.mappedQue()
            props?.visible()
        }
        Swal.fire({
            text: res?.message,
            confirmButtonText: 'Ok',
            showCancelButton: false,
          })
    }

    const updateMapped = async () => {
        let mappedOpts = []
        let allOpts = questionDetail?.mappedOptions
        let countValidate = 0;
        // console.log('validateOpt',validateOpt)
        allOpts?.map((opt) => {
            let enable = 0
            let validate = 0
            let getQuota = document.getElementById("quota"+opt?.option)
            let quota = getQuota?.value
            let eIndex = enabledOpt.findIndex(option=>option === opt?.option)
            if(eIndex > -1){
                enable = 1
            }
            let vIndex = validateOpt.findIndex(option=>option === opt?.option)
            if(vIndex > -1){
                validate = 1
                countValidate = countValidate+1;
            }
            // let getQuota = document.getElementById("quota"+opt)
            if(quota === ''){
                quota = 0
            }else{
                quota = parseInt(quota)
            }
            // quota = getQuota.value
            mappedOpts.push({option: opt.option, quota: quota, enabled: enable, validate: validate})
        })
        if(allOpts?.length > 0 && countValidate === 0){
            setValidateCountError("Please select at least one option")
            Swal.fire({
                text: 'Please select at least one valid option',
                confirmButtonText: 'Ok',
                showCancelButton: false,
              })
            return false
        }
        let payload = {
            projectDetailID: props?.detail?.projectDetailID,
            preScreenerQuestionID: props?.preScreenerQuestionID,
            mappedOptions: mappedOpts
        }
        let res = await postAPI('/project/prescreen/update-mapped-question/', payload)
        if(res.status === 1){
            props?.mappedQue()
            props?.visible()
        }
        Swal.fire({
            text: res?.message,
            confirmButtonText: 'Ok',
            showCancelButton: false,
          })
    }

    const cancel = async () => {
        props?.visible()
    }

    const toggleValidateAll = () => {
        setAllValidated((prev) => !prev); // Allow user to toggle
    };
    const toggleEnableAll = () => {
        setAllEnabled((prev) => !prev); // Allow user to toggle
    };

    return (<>
        <div className='row'>
            <div className='col-md-6'>
                <div className="row padTop">
                    <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                        <span className="fontBold">Title</span><span className="fontBold pull-right">:</span>
                    </div>
                    <div className="col-lg-8 col-md-8 col-7">
                        <span className="detail-list" id="questionTittle">{questionDetail?.title}</span>
                    </div>
                </div>
                <div className="row padTop">
                    <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                        <span className="fontBold">Question</span><span className="fontBold pull-right">:</span>
                    </div>
                    <div className="col-lg-8 col-md-8 col-7">
                        <span className="detail-list" id="Question">{questionDetail?.question}</span>
                    </div>
                </div>
                <div className="row padTop">
                    <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                        <span className="fontBold">Control Type</span><span className="fontBold pull-right">:</span>
                    </div>
                    <div className="col-lg-8 col-md-8 col-7">
                        <span className="detail-list" id="ControlType">{questionDetail?.controlTypeName}</span>
                    </div>
                </div>
            </div>
            <div className='table-responsive'>
            {questionDetail?.controlTypeSlug !== 'input-text-box' ? (
                <table className="table table-striped table-hover  mt-3">
                <thead>
                    <tr className="bg-primary" style={{ color: "white" }}>
                        <th scope="col">
                            <div className="form-check ">
                                <input className="form-check-input formcheckedbg" type="checkbox" id='enableAll' onClick={(e) => handleEnableAll(e)} checked={allEnabled} onChange={toggleEnableAll} />
                                <label className="form-check-label mx-2" >
                                    Enable
                                </label>
                            </div>
                        </th>
                        <th scope="col">Option</th>
                        <th scope="col">Quota</th>
                        <th scope="col">
                            <div className="form-check ">
                                <input className="form-check-input formcheckedbg" type="checkbox" id='validateAll' 
                                    disabled={questionDetail?.mappedOptions?.length != enabledOpt?.length ? true :false} 
                                    onClick={(e) => handleValidateAll(e)} checked={allValidated} onChange={toggleValidateAll}/>
                                <label className="form-check-label mx-2" >
                                    Validate
                                </label>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {questionDetail?.mappedOptions?.map((opt) => {
                        return (<>
                        <tr>
                            <td>  <div className="form-check ">
                                {/* <input className="form-check-input" type="checkbox" id='' value='' /> */}
                                <input 
                                    className="form-check-input chkEnable" 
                                    id={"chkEnable"+opt?.option?.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')}
                                    type="checkbox" 
                                    value={opt?.option} 
                                    defaultChecked={opt?.enabled === 1 ? true : false }
                                    onChange={(e) => handleEnableIndividual(e)} />
                            </div></td>
                            <td>{opt?.option}</td>
                            <td>  <div className='quota_dis'>    
                                <input type="number" className="form-control quotainput" defaultValue={opt?.quota} disabled={opt?.validate === 1 ? false : true} id={"quota"+opt?.option} />
                            </div>
                            </td>
                            <td><div className="form-check ">
                                <input 
                                    className="form-check-input chkValidate" 
                                    id={"chkValidate"+opt?.option?.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')}
                                    type="checkbox" 
                                    value={opt?.option} 
                                    disabled={opt?.enabled === 1 ? false : true}
                                    defaultChecked={opt?.validate === 1 ? true : false }
                                    onChange={(e) => handleValidateIndividual(e)} />
                            </div></td>
                        </tr>
                        </>)
                    })}
                    
                </tbody>
            </table>
            ) : (<></>)}
                

                <div style={{float:"right"}}>
                    {/* {props?.queBySearch ? (<>
                        {props?.mappedQuestionDetail?.isTempQuestion === "mappedQuestionDetailEdit" ? (<>
                            <button type="submit" className="btn btn-primary mx-2" onClick={()=>mapFromSearch()}>{"Submit"}</button>
                        </>) : (<>
                            <button type="submit" className="btn btn-primary mx-2" onClick={()=>updateMapped()}>{"Submit"}</button>
                        </>)}
                        
                    </>) : (<>
                        <button type="submit" className="btn btn-primary mx-2" onClick={()=>mapFromTemp()}>{"Submit"}</button>
                    </>)} */}

                    {props?.queBySearch ? 
                        (<><button type="submit" className="btn btn-primary mx-2" onClick={()=>mapFromSearch()}>{"Submit"}</button></>) 
                        : 
                        (<>{props?.mappedQuestionDetail?.isTempQuestion === 0 ? 
                            (<><button type="submit" className="btn btn-primary mx-2" onClick={()=>updateMapped()}>{"Submit"}</button></>) 
                            : (<><button type="submit" className="btn btn-primary mx-2" onClick={()=>mapFromTemp()}>{"Submit"}</button></>)}
                    </>)}
                    <CButton color="secondary" onClick={()=>cancel()}>Cancel</CButton>
                </div>
            </div>

        </div>
    </>)
}
export default PreScreenLinkQuestion