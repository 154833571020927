import React, { useEffect, useState } from "react";
import { ProjectDelete, ProjectList } from "../../Api/Project";
import {
  defaultPage
} from "../../Common/Constant";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Pagination from "../Layout/Pagination";
import Preloader from "../Layout/Preloader";
import Sidebar from "../Layout/Sidebar";
import { NavLink, useNavigate } from "react-router-dom";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody
} from "@coreui/react";
import moment from "moment";
import { ProjecGroupDetail } from "../../Api/ProjectGroup";

function All_project() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "All Project - Mindforce";
  });
  const [searchKeyword, setSearchKeyword] = useState();
  const [filter, setFilter] = useState([]);
  const [lists, setLists] = useState();
  const [dataCount, setDataCount] = useState();
  const [pageInfo, setPageInfo] = useState({});
  const [childRow, setchildRow] = useState(false);
  const [childRowData, setChildRowData] = useState();
  const [parentId, setParentId] = useState();
  const [detail, setDetail] = useState();
  const [redirectLinks, setRedirectLinks] = useState();
  const [visibleDetail, setVisibleDetail] = useState(false);
  useEffect(() => {
    getList();
  }, [filter]);

  const handleFilter = (e) => {
    setFilter([]);
    if (e.target.value !== "total") {
      setFilter([
        {
          field: e.target.name,
          value: e.target.value,
        },
      ]);
    }
  };

  const getList = async () => {
    let res = await ProjectList({
      page: pageInfo.current_page,
      recPerPage: defaultPage.perPage,
      searchKeyword: searchKeyword,
      filter: filter,
    });
    if (res.status === 1) {
      setLists(res.payload);
      setDataCount(res.dataCount);
      setPageInfo(res.paginationInfo);
    } else {
      setLists([]);
    }
  };

  const changePage = (p) => {
    pageInfo.current_page = p;
    getList();
  };

  const deleteRow = async (id, type, parentId = null) => {
    let payload = {
      type: type,
    };
    if (parentId) {
      payload.parentProjectID = parentId;
    }
    let res = await ProjectDelete({ id: id, payload: payload });
    if (res.status === 1) {
      getList();
    }
  };

  const projectDetailGroup = async (id) => {
    let res = await ProjecGroupDetail({ id: id });
    if (res.status === 1) {
      setDetail(res.payload[0]);
      setRedirectLinks(res?.redirectLinks);
      setVisibleDetail(true);
    }
  };

  const expdRow = (projectId) => {
    setParentId(projectId);

    if (!childRow) {
      setchildRow(true);
    }
    if (childRow) {
      setchildRow(false);
    }
  };

  return (
    <div>
      <Sidebar />
      <Header />
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid ">
              <div className="row">
                <div className="col-12 col-lg-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">All Projects</h4>

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a> Project</a>
                        </li>
                        <li className="breadcrumb-item active">All Projects</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}
              <div className="client_active_btn d-flex ">
                <input
                  type="radio"
                  className="btn-check"
                  name="status"
                  id="option1"
                  value="total"
                  onClick={(e) => handleFilter(e)}
                  autocomplete="off"
                />
                <div>
                  {" "}
                  <label
                    className="btn btn-white"
                    htmlFor="option1"
                    style={{
                      backgroundColor: filter[0] ? "white" : "#979292",
                      color: filter[0] ? "black" : "white",
                    }}
                  >
                    Total
                  </label>
                  <label htmlFor="option1" className="btn btn-color ">
                    {dataCount ? dataCount?.total : 0}
                  </label>
                </div>

                <input
                  type="radio"
                  className="btn-check "
                  name="status"
                  id="option2"
                  value="active"
                  onClick={(e) => handleFilter(e)}
                  autocomplete="off"
                />
                <div>
                  {" "}
                  <label
                    className="btn btn-white "
                    htmlFor="option2"
                    style={{
                      backgroundColor:
                        filter[0]?.value === "active" ? "#979292" : "white",
                      color: filter[0]?.value === "active" ? "white" : "black",
                    }}
                  >
                    Active{" "}
                  </label>
                  <label htmlFor="option2" className="btn btn-color1 ">
                    {dataCount ? dataCount?.active : 0}
                  </label>
                </div>

                <input
                  type="radio"
                  className="btn-check"
                  name="status"
                  id="option3"
                  value="inactive"
                  onClick={(e) => handleFilter(e)}
                  autocomplete="off"
                />
                <div>
                  {" "}
                  <label
                    className="btn btn-white "
                    htmlFor="option3"
                    style={{
                      backgroundColor:
                        filter[0]?.value === "inactive" ? "#979292" : "white",
                      color:
                        filter[0]?.value === "inactive" ? "white" : "black",
                    }}
                  >
                    In Active{" "}
                  </label>
                  <label htmlFor="option3" className="btn btn-color2 ">
                    {dataCount ? dataCount?.inactive : 0}
                  </label>
                </div>

                <input
                  type="radio"
                  className="btn-check"
                  name="status"
                  id="option4"
                  value="invoiced"
                  onClick={(e) => handleFilter(e)}
                  autocomplete="off"
                />
                <div>
                  {" "}
                  <label
                    className="btn btn-white"
                    htmlFor="option4"
                    style={{
                      backgroundColor:
                        filter[0]?.value === "invoiced" ? "#979292" : "white",
                      color:
                        filter[0]?.value === "invoiced" ? "white" : "black",
                    }}
                  >
                    Invoiced{" "}
                  </label>
                  <label htmlFor="option4" className="btn btn-color3 ">
                    {dataCount ? dataCount?.invoiced : 0}
                  </label>
                </div>

                <input
                  type="radio"
                  className="btn-check"
                  name="status"
                  id="option5"
                  value="closed"
                  onClick={(e) => handleFilter(e)}
                  autocomplete="off"
                />
                <div>
                  {" "}
                  <label
                    className="btn btn-white"
                    htmlFor="option5"
                    style={{
                      backgroundColor:
                        filter[0]?.value === "closed" ? "#979292" : "white",
                      color: filter[0]?.value === "closed" ? "white" : "black",
                    }}
                  >
                    Closed{" "}
                  </label>
                  <label htmlFor="option5" className="btn btn-color4 ">
                    {dataCount ? dataCount?.closed : 0}
                  </label>
                </div>

                <div className="search" style={{ display: "flex" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    autocomplete="off"
                    id="search-options"
                    defaultValue={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") getList();
                    }}
                  />
                  <button className="btn btn-primary" onClick={() => getList()}>
                    <span className="mdi mdi-magnify search-widget-icon"></span>
                  </button>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-xl-12 col-lg-12">
                  <div className="card">
                    {/* <!-- end card header --> */}

                    <div className="card-body">
                      {lists ? (
                        <>
                          <div className="table-responsive table-scroll-fixed">
                            <table className="table table-striped table-hover  table_client">
                              <thead>
                                <tr
                                  className="bg-primary"
                                  style={{ color: "white" }}
                                >
                                  <th scope="col">Sr.No.</th>
                                  <th
                                    scope="col"
                                    style={{
                                      minWidth: "140px",
                                      textAlign: "left"
                                    }}
                                  >
                                    Action
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      minWidth: "150px",
                                      maxWidth: "150px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Code
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      minWidth: "150px",
                                      maxWidth: "150px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      minWidth: "150px",
                                      maxWidth: "150px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Client Name
                                  </th>
                                  <th scope="col " className="zi">
                                    Country
                                  </th>
                                  <th scope="col">Starts</th>
                                  <th scope="col">C</th>
                                  <th scope="col">T</th>
                                  <th scope="col">Q</th>
                                  <th scope="col">D</th>
                                  <th scope="col">LastComplete</th>
                                  <th scope="col">EPC</th>
                                  <th scope="col">S.Size</th>
                                  <th scope="col">IR(%)</th>
                                  <th scope="col">CR(%)</th>
                                  <th scope="col">DR(%)</th>
                                  <th scope="col">LOI</th>
                                  <th scope="col">CPI</th>
                                  <th scope="col">PS</th>
                                </tr>
                              </thead>
                              <tbody>
                                {lists?.length > 0 ? (
                                  <>
                                    {lists?.map((row, i) => {
                                      return (
                                        <>
                                          <tr>
                                            <td scope="row">{i + 1}</td>
                                            <td style={{ textAlign: "left" }}>
                                              {row?.childProjects?.length >
                                                0 ? (
                                                <>
                                                  {row?.recontactParentID > 0 ? (
                                                    <>
                                                      <span className="p-1 bg-blue-circle" title="Recontact">
                                                        {" "}
                                                        R
                                                        {" "}
                                                      </span>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  <span className="p-1">
                                                    <i
                                                      className="ri-add-fill pointerC"
                                                      onClick={() =>
                                                        navigate(
                                                          "/group-project-child-add",
                                                          {
                                                            state:
                                                              row.projectID,
                                                          }
                                                        )
                                                      }
                                                    ></i>
                                                  </span>
                                                  <span className="p-1">
                                                    <i
                                                      className="ri-edit-2-line pointerC"
                                                      onClick={() =>
                                                        navigate(
                                                          "/group-project-edit",
                                                          {
                                                            state:
                                                              row?.projectID,
                                                          }
                                                        )
                                                      }
                                                    ></i>
                                                  </span>
                                                  {/* <span className="p-1"><i className="ri-delete-bin-2-line del pointerC" onClick={() => deleteRow(row?.projectID, 'group')}></i></span> */}
                                                  <span className="p-1">
                                                    <i
                                                      className="ri-arrow-right-down-line pointerC"
                                                      id={
                                                        "child" + row?.projectID
                                                      }
                                                      onClick={() =>
                                                        expdRow(row?.projectID)
                                                      }
                                                    ></i>
                                                  </span>
                                                </>
                                              ) : (
                                                <>
                                                  {row?.notes ? (
                                                    <>
                                                      <span className="p-1 bg-blue-circle">
                                                        <i className="ri-file-text-line"></i>
                                                      </span>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {row?.isSecurityEnabled ? (
                                                    <>
                                                      <span className="p-1 bg-blue-circle">
                                                        {" "}
                                                        S{" "}
                                                      </span>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {row?.recontactParentID > 0 ? (
                                                    <>
                                                      <span className="p-1 bg-blue-circle" title="Recontact">
                                                        {" "}
                                                        R
                                                        {" "}
                                                      </span>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {/* <span className="p-1"><i className="ri-delete-bin-2-line del pointerC" onClick={() => deleteRow(row?.projectDetailID, 'single')}></i></span> */}
                                                </>
                                              )}
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {row?.childProjects.length > 0 ? (
                                                <>
                                                  <NavLink
                                                    onClick={() =>
                                                      projectDetailGroup(
                                                        row?.projectID
                                                      )
                                                    }
                                                  >
                                                    {row?.currentStatus ===
                                                      "active" ? (
                                                      <>
                                                        <span className="badge bg-success active_btn">
                                                          a
                                                        </span>{" "}
                                                      </>
                                                    ) : (
                                                      <>
                                                        {row?.currentStatus ===
                                                          "inactive" ? (
                                                          <>
                                                            <span className="badge bg-warning inactive_btn">
                                                              a
                                                            </span>
                                                          </>
                                                        ) : (
                                                          <>
                                                            {row?.currentStatus ===
                                                              "invoiced" ? (
                                                              <>
                                                                <span className="badge Invoiced_btn">
                                                                  a
                                                                </span>
                                                              </>
                                                            ) : (
                                                              <>
                                                                {row?.currentStatus ===
                                                                  "closed" ? (
                                                                  <>
                                                                    <span className="badge bg-danger closed_btn">
                                                                      a
                                                                    </span>
                                                                  </>
                                                                ) : (
                                                                  <></>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                    <span className="clickableText">
                                                      {" " + row.projectCode}
                                                    </span>
                                                  </NavLink>
                                                </>
                                              ) : (
                                                <>
                                                  <NavLink
                                                    to={
                                                      "/single-project-detail?id=" +
                                                      row?.projectDetailID
                                                    }
                                                  >
                                                    {row?.currentStatus ===
                                                      "active" ? (
                                                      <>
                                                        <span className="badge bg-success active_btn">
                                                          a
                                                        </span>{" "}
                                                      </>
                                                    ) : (
                                                      <>
                                                        {row?.currentStatus ===
                                                          "inactive" ? (
                                                          <>
                                                            <span className="badge bg-warning inactive_btn">
                                                              a
                                                            </span>
                                                          </>
                                                        ) : (
                                                          <>
                                                            {row?.currentStatus ===
                                                              "invoiced" ? (
                                                              <>
                                                                <span className="badge Invoiced_btn">
                                                                  a
                                                                </span>
                                                              </>
                                                            ) : (
                                                              <>
                                                                {row?.currentStatus ===
                                                                  "closed" ? (
                                                                  <>
                                                                    <span className="badge bg-danger closed_btn">
                                                                      a
                                                                    </span>
                                                                  </>
                                                                ) : (
                                                                  <></>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                    <span className="clickableText">
                                                      {" " + row.projectCode}
                                                    </span>
                                                  </NavLink>
                                                </>
                                              )}
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {row?.fullName}
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {row?.clientName}
                                            </td>
                                            <td>{row?.countryName}</td>
                                            <td>{row?.starts}</td>
                                            <td>{row?.c}</td>
                                            <td>{row?.t}</td>
                                            <td>{row?.q}</td>
                                            <td>{row?.d}</td>
                                            <td>
                                              {row?.lastComplete === 0 ? (
                                                <>-</>
                                              ) : (
                                                <>
                                                  {moment(
                                                    row?.lastComplete * 1000
                                                  ).format("DD-MM-yyyy")}
                                                </>
                                              )}
                                            </td>
                                            <td>{row?.epc}</td>
                                            <td>{row?.sampleSize}</td>
                                            <td>{row?.ir}</td>
                                            <td>{row?.cr}</td>
                                            <td>{row?.dr}</td>
                                            <td>{row?.loi}</td>
                                            <td>{row?.cpi}</td>
                                            <td>
                                              <div className="form-check ">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id="formCheck1"
                                                  {...(row?.preScreen
                                                    ? { checked: true }
                                                    : { checked: false })}
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                          {childRow &&
                                            parentId &&
                                            row?.projectID === parentId ? (
                                            <>
                                              {row?.childProjects.length > 0 ? (
                                                <>
                                                  {row?.childProjects?.map(
                                                    (child) => {
                                                      return (
                                                        <>
                                                          <tr>
                                                            <td></td>
                                                            <td>
                                                              {child?.notes ? (
                                                                <>
                                                                  <span className="p-1 bg-blue-circle">
                                                                    <i className="ri-file-text-line"></i>
                                                                  </span>
                                                                </>
                                                              ) : (
                                                                <></>
                                                              )}
                                                              {child?.isSecurityEnabled ? (
                                                                <>
                                                                  <span className="p-1 bg-blue-circle">
                                                                    {" "}
                                                                    S{" "}
                                                                  </span>
                                                                </>
                                                              ) : (
                                                                <></>
                                                              )}
                                                              {child?.recontactParentID > 0 ? (
                                                                <>
                                                                  <span className="p-1 bg-blue-circle" title="Recontact">
                                                                    {" "}
                                                                    R
                                                                    {" "}
                                                                  </span>
                                                                </>
                                                              ) : (
                                                                <></>
                                                              )}
                                                            </td>
                                                            <td
                                                              style={{
                                                                textAlign:
                                                                  "left",
                                                              }}
                                                            >
                                                              {/* <span className="clickableText" onClick={() => navigate('/group-project-child-detail?id=' + child?.projectDetailID, { state: child?.projectDetailID })}>{child?.projectCode}</span> */}
                                                              <NavLink
                                                                className="clickableText"
                                                                to={
                                                                  "/group-project-child-detail?id=" +
                                                                  child?.projectDetailID
                                                                }
                                                              >
                                                                {
                                                                  child?.projectCode
                                                                }
                                                              </NavLink>
                                                            </td>
                                                            <td
                                                              style={{
                                                                textAlign:
                                                                  "left",
                                                              }}
                                                            >
                                                              {child?.fullName}
                                                            </td>
                                                            <td
                                                              style={{
                                                                textAlign:
                                                                  "left",
                                                              }}
                                                            >
                                                              {
                                                                child?.clientName
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                child?.countryName
                                                              }
                                                            </td>
                                                            <td>
                                                              {child?.starts}
                                                            </td>
                                                            <td>{child?.c}</td>
                                                            <td>{child?.t}</td>
                                                            <td>{child?.q}</td>
                                                            <td>{child?.d}</td>
                                                            <td>
                                                              {child?.lastComplete ===
                                                                0 ? (
                                                                <>-</>
                                                              ) : (
                                                                <>
                                                                  {moment(
                                                                    child?.lastComplete *
                                                                    1000
                                                                  ).format(
                                                                    "DD-MM-yyyy"
                                                                  )}
                                                                </>
                                                              )}
                                                            </td>
                                                            <td>
                                                              {child?.epc}
                                                            </td>
                                                            <td>
                                                              {
                                                                child?.sampleSize
                                                              }
                                                            </td>
                                                            <td>{child?.ir}</td>
                                                            <td>{child?.cr}</td>
                                                            <td>{child?.dr}</td>
                                                            <td>
                                                              {child?.loi}
                                                            </td>
                                                            <td>
                                                              {child?.cpi}
                                                            </td>
                                                            <td>
                                                              <div className="form-check ">
                                                                <input
                                                                  className="form-check-input"
                                                                  type="checkbox"
                                                                  id="formCheck1"
                                                                />
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>
                                    <tr>
                                      <td colSpan={13}>
                                        <center>No data found</center>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : (
                        <>
                          <Preloader />
                        </>
                      )}

                      <Pagination pageInfo={pageInfo} changePage={changePage} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {visibleDetail ? (
        <>
          <>
            {/* <CButton onClick={() => setVisibleDetail(!visibleDetail)}>Launch static backdrop modal</CButton> */}
            <CModal
              visible={visibleDetail}
              backdrop={"static"}
              size={"xl"}
              onClose={() => setVisibleDetail(false)}
            >
              <CModalHeader
                style={{
                  backgroundColor: "#005499",
                  paddingBottom: "10px",
                  margin: "-1px",
                }}
                closeButton={false}
              >
                <CModalTitle style={{ color: "#fff", width: "100%" }}>
                  Group Project Details
                  <span
                    style={{ float: "right", cursor: "pointer" }}
                    onClick={() => setVisibleDetail(false)}
                  >
                    {" "}
                    X{" "}
                  </span>
                </CModalTitle>
              </CModalHeader>
              <CModalBody>
                <div className="row mt-2">
                  <div className="col-xl-12 col-lg-12">
                    <table className="GroupProjectDetailTable">
                      <tr>
                        <th>
                          Client Name <span className="colonSeperator">:</span>
                        </th>
                        <td>{detail?.clientName}</td>
                      </tr>
                      <tr>
                        <th>
                          Project Code <span className="colonSeperator">:</span>
                        </th>
                        <td>{detail?.projectCode}</td>
                      </tr>
                      <tr>
                        <th>
                          Project Name <span className="colonSeperator">:</span>{" "}
                        </th>
                        <td>{detail?.fullName}</td>
                      </tr>
                      <tr>
                        <th>
                          Project Description{" "}
                          <span className="colonSeperator">:</span>{" "}
                        </th>
                        <td>{detail?.description}</td>
                      </tr>
                      {detail?.dynamicThanksEnabled && (
                        <tr>
                          <th>
                            Dynamic Thanks URL <span className="colonSeperator">:</span>
                          </th>
                          <td>
                            <input
                              type="checkbox"
                              id="frmdynamicThanksEnabled"
                              defaultChecked={detail?.dynamicThanksEnabled}
                              data-switch="success"
                              disabled
                              style={{ backgroundColor: "#000", borderColor: "#000" }}
                            />
                          </td>
                        </tr>
                      )}
                    </table>
                  </div>
                </div>
                <div className="form_divide">
                  <hr></hr>
                </div>
                <div className="row mt-2">
                  <div className="col-xl-12 col-lg-12">
                    <h3>Redirect Link for Client</h3>
                  </div>
                  <div className="col-xl-12 col-lg-12">
                    <table className="GroupProjectDetailTable">
                      {redirectLinks?.map((rl, i) => {
                        return (<>
                          <tr>
                            <th>
                              {rl?.label}{" "}
                              <span className="colonSeperator">:</span>{" "}
                            </th>
                            <td>{rl?.link}</td>
                          </tr>
                        </>)
                      })}
                    </table>
                  </div>
                </div>
                <div className="form_divide">
                  <hr></hr>
                </div>
                <div className="row mt-2">
                  <div className="col-xl-12 col-lg-12">
                    <h3>Notes</h3>
                  </div>
                  <div className="col-xl-12 col-lg-12"></div>
                </div>
              </CModalBody>
            </CModal>
          </>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default All_project;
