import React, { useState } from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import { useEffect } from 'react'
import { ClientList } from '../../Api/Master'
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import { ProjecGroupDetail, ProjectGroupUpdate, validateFormDataGroupUpdate } from '../../Api/ProjectGroup'

const defaultStatus = [
    {id:1, label:"Active", value:"active"},
    {id:2, label:"In Active", value:"inactive"},
    {id:3, label:"Invoiced", value:"invoiced"},
    {id:4, label:"Closed", value:"closed"},
]
function Group_project_edit() {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        document.title = "Group Project child - Mindforce"
    },);
    const [detail, setDetail] = useState();
    const [clients, setClients] = useState();
    const [form, setForm] = useState();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getDetail()
        getClients()
    },[])

    const getDetail = async () => {
        let res = await ProjecGroupDetail({id:location.state})
        if(res.status === 1){
            let det = res.payload[0]
            setForm({
                fullName: det?.fullName,
                description: det?.description,
                currentStatus: det?.currentStatus,
                clientCode: det?.clientCode,
                dynamicThanksEnabled: det?.dynamicThanksEnabled,
                notes: det?.notes
            })
            setDetail(det);
        }
    }

    const getClients = async() => {
        let res = await ClientList({page:0, searchKeyword:''})
        if(res.status === 1){
            setClients(res.payload)
        }
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })
        if (!!errors[field])
            setErrors({
                ...errors,
            [field]: null
        })
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        setErrors()
        const formErrors = validateFormDataGroupUpdate(form)
        if(Object.keys(formErrors).length > 0){
            setErrors(formErrors)
        }else{
            let res = await ProjectGroupUpdate({id:detail?.projectID, payload:form});
            if(res.status === 1){
                navigate("/all-project");
            }else{
                setErrors({...errors,['submit']:res.message})
            }
        }
        
    }
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Edit Group Project</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a>Project</a></li>
                                                <li className="breadcrumb-item active">Edit Group Project</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">

                                        {/* <!-- end card header --> */}

                                        <div className="card-body">
                                            <div className="">
                                                <form >
                                                    <div className="row">
                                                        <div className="col-md-9">
                                                            <div className="mb-3" style={{fontSize:"18px", fontWeight:"bold"}}>
                                                                <label htmlFor="frmdynamicThanksEnabled" className="form-label mb-0">Dynamic Thanks</label>
                                                                &nbsp;&nbsp;&nbsp;
                                                                <input type="checkbox" id="frmdynamicThanksEnabled" defaultChecked={form?.dynamicThanksEnabled} onClick={(e) => setField('dynamicThanksEnabled',e.target.checked)} data-switch="success" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="mb-3">
                                                                <span style={{float:"right"}}>
                                                                {/* <b>Status</b> &nbsp;  */}
                                                                <select className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('status',e.target.value)}>
                                                                    <option value={""} disabled selected>Select Status</option>
                                                                    {defaultStatus?.map((ds) => {
                                                                        return (<option value={ds.value} selected={ds.value === form?.currentStatus ? true:false}>{ds.label}</option>)
                                                                    })}
                                                                </select>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        

                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Client <span className="required_field">*{errors?.clientID}</span></label>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" disabled={true}>
                                                                    <option value={""} disabled selected>Select Client</option>
                                                                    {clients?.map((client) => {
                                                                        return (<option value={client.clientID} selected={client.clientCode === form?.clientCode ? true:false}>{client.clientCode + ' : ' + client.clientName}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ProjectName" className="form-label mb-0">Project Name <span className="required_field">*{errors?.fullName}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter project name" id="fullName" defaultValue={form?.fullName} onChange={(e) => setField('fullName',e.target.value)} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="Projectdescription" className="form-label mb-0">Project Description <span className="required_field">*{errors?.description}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter project description" id="description" defaultValue={form?.description} onChange={(e) => setField('description',e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="form_divide"><hr ></hr></div>
                                                        <div className="col-md-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="exampleFormControlTextarea5" className="form-label mb-0">Notes </label>
                                                                <textarea className="form-control" id="exampleFormControlTextarea5" rows="3" defaultValue={form?.notes}></textarea>
                                                                <span className="max_char">Maximum limit of characters 250</span>
                                                            </div>
                                                        </div>


                                                        <div className="col-lg-12">
                                                            <div className="text-end">
                                                                <button type="submit" className="btn btn-primary mx-2" onClick={handleUpdate}>Update</button>
                                                                <button type="submit" className="btn btn-secondary">Cancel</button>
                                                            </div>
                                                        </div>


                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end col --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Group_project_edit